import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomIconService {
  private readonly svgIcons: Array<Record<string, string>> = [
    { pick: './assets/icons/delivery-truck.svg' },
    { receive: './assets/icons/warehouse-package-box.svg' },
    { sort: './assets/icons/stacked-packages.svg' },
    { dispatch: './assets/icons/warehouse-package.svg' },
    { 'daily-close': './assets/icons/business-contract-approve.svg' },
    { cage: './assets/icons/warehouse-storage.svg' },
    { cash: './assets/icons/accounting-coins-bill.svg' },
    { 'arrow-head-up-bold': './assets/icons/arrow-head-up-bold.svg' },
    { 'arrow-head-down-bold': './assets/icons/arrow-head-down-bold.svg' },
    { 'arrow-head-up': './assets/icons/arrow-head-up.svg' },
    { 'arrow-head-down': './assets/icons/arrow-head-down.svg' },
    { users: './assets/icons/user-management.svg' },
    { 'icon-info': './assets/icons/icon-info.svg' },
    { 'icon-error': './assets/icons/icon-error.svg' },
    { 'icon-success': './assets/icons/icon-success.svg' },
    { 'icon-warning': './assets/icons/icon-warning.svg' },
    { user: './assets/icons/user.svg' },
    { search: './assets/icons/search.svg' },
    { 'setting-vertical': './assets/icons/setting-vertical.svg' },
    { 'navigation-menu': './assets/icons/navigation-menu.svg' },
    { position: './assets/icons/position.svg' },
    { earth: './assets/icons/earth.svg' },
    { email: './assets/icons/email.svg' },
    { phone: './assets/icons/phone.svg' },
    { 'pin-location': './assets/icons/pin-location.svg' },
    { 'warehouse-storage-2': './assets/icons/warehouse-storage-2.svg' },
    { 'file-edit': './assets/icons/file-edit.svg' },
    { data: './assets/icons/data.svg' },
    { fleet: './assets/icons/fleet.svg' },
    { 'check-circle': './assets/icons/check-circle.svg' },
    { 'check-circle-gap': './assets/icons/check-circle-gap.svg' },
    { 'disable-hollow': './assets/icons/disable-hollow.svg' },
    { angle: './assets/icons/angle.svg' },
    { radioactive: './assets/icons/radioactive.svg' },
    { radioactivecircle: './assets/icons/radioactive-circle.svg' },
    { alertdiamond: './assets/icons/alert-diamond.svg' },
    { close: './assets/icons/close.svg' },
    { 'navigation-vertical-menu': './assets/icons/navigation-vertical-menu.svg' },
    { 'arrow-flat-right': './assets/icons/arrow-flat-right.svg' },
    { 'arrow-flat-left': './assets/icons/arrow-flat-left.svg' },
    { calendar: './assets/icons/calendar.svg' },
    { 'move-right': './assets/icons/move-right.svg' },
    { megaphone: './assets/icons/megaphone.svg' },
    { 'settings-slider': './assets/icons/settings-slider.svg' },
    { operations: './assets/icons/operations.svg' },
    { consolidation: './assets/icons/shipment-add.svg' },
    { 'move-left': './assets/icons/move-left.svg' },
    { minSize: './assets/icons/min-size.svg' },
    { maxSize: './assets/icons/max-size.svg' },
    { minimize: './assets/icons/head-down.svg' },
    { logout: './assets/icons/logout.svg' },
    { reorder: './assets/icons/reorder.svg' },
    { copy: './assets/icons/copy.svg' },
    { 'icon-close': './assets/icons/icon-close.svg' },
    { 'ops-admin': './assets/icons/ops-admin.svg' },
    { cage: './assets/icons/cage.svg' },
    { hub: './assets/icons/hub.svg' },
    { 'last-mile': './assets/icons/last-mile.svg' },
    { driver: './assets/icons/driver.svg' },
    { shelf: './assets/icons/shelf.svg' },
    { lobby: './assets/icons/lobby.svg' },
    { dashboards: './assets/icons/dashboards.svg' },
    { bin: './assets/icons/bin.svg' },
    { show: './assets/icons/show.svg' },
    { hide: './assets/icons/hide.svg' },
    { box: './assets/icons/box.svg' },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public registerIcons(): void {
    for (let i = 0, len = this.svgIcons.length; i < len; i++) {
      const url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.svgIcons[i][Object.keys(this.svgIcons[i])[0]]);
      this.matIconRegistry.addSvgIcon(Object.keys(this.svgIcons[i])[0], url);
    }
  }
}
