import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthLoginComponent } from '@auth/components/auth-login/auth-login.component';
import { AppRootComponent } from '@core/components/app-root/app-root.component';
import { AuthRequiredGuard } from '@auth/guards/auth-required.guard';
import { ForgotPasswordComponent } from '@auth/components/forgot-password/forgot-password.component';

export const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: AuthLoginComponent,
    title: 'Cerebro - Login',
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent,
    title: 'Cerebro - Forgot Password',
  },
  {
    path: 'code-verification',
    pathMatch: 'full',
    component: ForgotPasswordComponent,
    title: 'Cerebro - Code Verification',
  },
  {
    path: 'reset-password',
    pathMatch: 'full',
    component: ForgotPasswordComponent,
    title: 'Cerebro - Reset Password',
  },
  {
    path: 'app',
    pathMatch: 'prefix',
    component: AppRootComponent,
    canActivate: [AuthRequiredGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
      {
        path: 'home',
        loadChildren: () => import('./core/components/home/home.module').then((m) => m.HomeModule),
        title: 'Cerebro - Home',
      },
      {
        path: 'search',
        pathMatch: 'prefix',
        loadChildren: () => import('./search/search.module').then((m) => m.SearchModule),
        title: 'Cerebro - Search',
      },
      {
        path: 'administration',
        pathMatch: 'prefix',
        canActivate: [AuthRequiredGuard],
        loadChildren: () => import('./administration/administration.module').then((m) => m.AdministrationModule),
        title: 'Cerebro - System Administration',
      },
      {
        path: 'operations',
        pathMatch: 'prefix',
        canActivate: [AuthRequiredGuard],
        loadChildren: () => import('./operations/operations.module').then((m) => m.OperationsModule),
        title: 'Cerebro - Operations',
      },
      {
        path: 'reporting',
        pathMatch: 'prefix',
        canActivate: [AuthRequiredGuard],
        loadChildren: () => import('./reporting/reporting.module').then((m) => m.ReportingModule),
        title: 'Cerebro - Reporting',
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '',
  },
];

@NgModule({
  // imports: [RouterModule.forRoot(ROUTES, { enableTracing: true })],
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
